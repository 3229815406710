import React from 'react';
import './styles.css';

const Alert = (props) => {
  let alertType = 'alert alert-';
  alertType += props.alertType;
  if (props.showAlert) {
    return (
      <>
        <div className="alertContainer transition transition-all ease-in-out">
          <div className={alertType}>
            {props.text}
          </div>
        </div>
      </>
    );
  }
  return (
    <></>
  );
};

export default Alert;