import React from 'react';
import './styles.css';
import {REPORT} from "../../helpers";
import cx from 'classnames';

const Report = (props) => {
	const report = REPORT;
	console.log(report);

	const getScoreBackgroundClass = (score) => {
		if (0 < score < 2.5) {
			return 'bg-green-600';
		}
		if (2.6 < score < 3.5) {
			return 'bg-red-600';
		}
		if (3.5 < score < 5) {
			return 'bg-red-600';
		}
	};

	return (
		<div className="container reportView">
			<div className="row">
				<div className="col-5 col">
					<h3 className="text-2xl">Spam Score:</h3>
					<div className={cx('text-9xl border border-primary flex justify-center w-64 h-64 items-center text-white ', getScoreBackgroundClass(report.spam_score))}>
						{report.spam_score} <span className="text-3xl">{' '} / 5</span>
					</div>
				</div>
				<div className="col-7 col">
					{/* <h3 className="text-2xl">
						Spam Report:
					</h3>
					<code>
						{report.spam_report}
					</code> */}
				</div>
			</div>
			{/* <div>
				<h3 className="text-2xl">Message Details <small><em><a href={report.downloadUrl} target="_blank">Download message</a></em></small></h3>
				<div>
					<strong>From Name: </strong> {report.from_parsed[0].name}
				</div>
				<div>
					<strong>From Address: </strong> {report.from_parsed[0].address}
				</div>
				<div>
					<strong>Message Body: </strong>
					<div dangerouslySetInnerHTML={{__html: report.html}}></div>
				</div>
			</div> */}
			<div>
				<h3 className="text-2xl">Headers</h3>
				<table className="table-hover">
					<tbody>
						{report.headers.map(el => {
							return (
								<tr>
									<td>{el.key}</td> <td>{el.line}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
				
			</div>
		</div>
	);
};

export default Report;
