export  const REPORT = {
	"cc": "",
	"date": 1623775558000,
	"headers": [
		{
			"line": "Authentication-Results: inbox.testmail.app; spf=none smtp.mailfrom=arslanaslam.me; dkim=pass header.i=@arslanaslam-me.20150623.gappssmtp.com",
			"key": "authentication-results"
		},
		{
			"line": "Received-SPF: None (inbox.testmail.app: domain of arslanaslam.me does not designate 209.85.167.53 as permitted sender) receiver=inbox.testmail.app; identity=mailfrom; client-ip=209.85.167.53 helo=mail-lf1-f53.google.com; envelope-from=<hello@arslanaslam.me>",
			"key": "received-spf"
		},
		{
			"line": "Received-SPF: None (inbox.testmail.app: domain of mail-lf1-f53.google.com does not designate 209.85.167.53 as permitted sender) receiver=inbox.testmail.app; identity=helo; client-ip=209.85.167.53 helo=mail-lf1-f53.google.com; envelope-from=<hello@arslanaslam.me>",
			"key": "received-spf"
		},
		{
			"line": "X-Envelope-From: hello@arslanaslam.me",
			"key": "x-envelope-from"
		},
		{
			"line": "X-Envelope-To: s7st7.UXXlZS@inbox.testmail.app",
			"key": "x-envelope-to"
		},
		{
			"line": "Received: from mail-lf1-f53.google.com (mail-lf1-f53.google.com [209.85.167.53])\r\n\tby inbox.testmail.app (Haraka/2.8.26) with ESMTPS id 31E03F1B-9C6D-455E-9CD0-D3201A78FE9C.1\r\n\tenvelope-from <hello@arslanaslam.me>\r\n\t(version=TLSv1.3 cipher=TLS_AES_256_GCM_SHA384);\r\n\tTue, 15 Jun 2021 16:46:11 +0000",
			"key": "received"
		},
		{
			"line": "Received: by mail-lf1-f53.google.com with SMTP id r198so28067896lff.11\r\n        for <s7st7.UXXlZS@inbox.testmail.app>; Tue, 15 Jun 2021 09:46:11 -0700 (PDT)",
			"key": "received"
		},
		{
			"line": "DKIM-Signature: v=1; a=rsa-sha256; c=relaxed/relaxed;\r\n        d=arslanaslam-me.20150623.gappssmtp.com; s=20150623;\r\n        h=mime-version:from:date:message-id:subject:to;\r\n        bh=JJ4lVbas5ZKRqr2oj0rSfu0WJUbi3/bnFqZX7RY/HVo=;\r\n        b=sy1fRsQuz9yj/YznPUN2lfqSFuCsL96I4lUnOEA8rf1uPVRQVXpwZtMqolSqKE9r7u\r\n         hteNtSfOIc9blGCSv3AGdQtKFaJLzSzbXXoSfqkccKykhhLcm8SxA2paeNvcNeN4Uj/q\r\n         aqkBbZppol0VXF0PK76KJHoWImgq/3mtBXCZNAPXnJt4CErkd9SX6/P7musTr90glIzf\r\n         X0uxG5dYsZ1ViTKT6NGQfJqcbRixuvVvu6PVN+Ks88VUIn81KMmd0lweFjAzmq73/6ng\r\n         4w44PcdA9MUnZkzm+jL5fNff5GL6ISKFmR8uGkVFm46TiSQ4i0P8s5hVx3cKQZOr8exx\r\n         gDlw==",
			"key": "dkim-signature"
		},
		{
			"line": "X-Google-DKIM-Signature: v=1; a=rsa-sha256; c=relaxed/relaxed;\r\n        d=1e100.net; s=20161025;\r\n        h=x-gm-message-state:mime-version:from:date:message-id:subject:to;\r\n        bh=JJ4lVbas5ZKRqr2oj0rSfu0WJUbi3/bnFqZX7RY/HVo=;\r\n        b=AatpNQax1VYCezWTyorUaQdB/L/i7hGwjB0M9FzD0CdYc/TqujxUdFIMXbKY+HlDc4\r\n         yHBlW64KHQbj+ytqsv/Ut2yZriGUMQo6EGiEZ5ddkv4Y12MFKaFJiM1cLRX8jKo2UNDv\r\n         vW2HWZU30u+XYcupB0+TArxSrsoS52rOvDI05q96fnwyE6UqfgmwCt4UcJfjOTzCPqi3\r\n         zxspC+AjyireUTAmIhVOpdVuLzfg6pPoohvDztzQbbVt4F9TowAb8d5rIwmcb94WFJK3\r\n         EAy0jWYqB30z0zzzNjSE5FYh8gjPTt9zKR1bFhYBKW3PwbUJFMjuOrxneLoPaQr+9rmO\r\n         vX/A==",
			"key": "x-google-dkim-signature"
		},
		{
			"line": "X-Gm-Message-State: AOAM533/gVGXtJ7cCSoZX6C+STm18rHPIL0g+aZhDna85e2CoFCxyb7o\r\n\tW/74furmt2sd9r55K7FrMS86kOynBDabaiUOG1a6LvvTcPt3QA==",
			"key": "x-gm-message-state"
		},
		{
			"line": "X-Google-Smtp-Source: ABdhPJwMs545Ev/llQQ0ZHPGrEnTQwPkk5Wihp2YrnASsQU0gr88FK+Vb1Eb9pTDpQjs9SkNINkEz7+JwgYSATia1DE=",
			"key": "x-google-smtp-source"
		},
		{
			"line": "X-Received: by 2002:a05:6512:681:: with SMTP id t1mr224590lfe.640.1623775570165;\r\n Tue, 15 Jun 2021 09:46:10 -0700 (PDT)",
			"key": "x-received"
		},
		{
			"line": "MIME-Version: 1.0",
			"key": "mime-version"
		},
		{
			"line": "From: Muhammad Arslan Aslam <hello@arslanaslam.me>",
			"key": "from"
		},
		{
			"line": "Date: Tue, 15 Jun 2021 21:45:58 +0500",
			"key": "date"
		},
		{
			"line": "Message-ID: <CABmY2TkY-TNSMx_wKKHgWGGYBcSZ-V4Vqbm_7dyTzFA0a3c25A@mail.gmail.com>",
			"key": "message-id"
		},
		{
			"line": "Subject: test emai",
			"key": "subject"
		},
		{
			"line": "To: s7st7.UXXlZS@inbox.testmail.app",
			"key": "to"
		},
		{
			"line": "Content-Type: multipart/alternative; boundary=\"0000000000000e1a7905c4d0b63d\"",
			"key": "content-type"
		},
		{
			"line": "X-Spam-Status: No, score=1.8 required=5.0",
			"key": "x-spam-status"
		}
	],
	"spam_report": "\r\n\tSpam detection software running on \"inbox.testmail.app\"\r\n\thas analyzed this email and generated the following report.\r\n\tSpam score: 1.8 points (lower is better)\r\n\tSpam threshold: 5.0 points (don't exceed this)\r\n\tContent analysis details:\r\n\tpts rule name              description\r\n\t---- ---------------------- --------------------------------------------------\r\n\t0.0 SPF_NONE               SPF: sender does not publish an SPF Record\r\n\t0.0 SPF_HELO_NONE          SPF: HELO does not publish an SPF Record\r\n\t1.6 HTML_IMAGE_ONLY_12     BODY: HTML: images with 800-1200 bytes of\r\n\twords\r\n\t0.0 HTML_MESSAGE           BODY: HTML included in message\r\n\t0.1 DKIM_SIGNED            Message has a DKIM or DK signature, not necessarily\r\n\tvalid\r\n\t-0.1 DKIM_VALID             Message has at least one valid DKIM or DK signature\r\n\t0.1 HTML_SHORT_LINK_IMG_1  HTML is very short with a linked image\r\n\t0.0 T_REMOTE_IMAGE         Message contains an external image",
	"attachments": [],
	"dkim": "pass",
	"envelope_to": "s7st7.UXXlZS@inbox.testmail.app",
	"subject": "test emai",
	"SPF": "none",
	"messageId": "<CABmY2TkY-TNSMx_wKKHgWGGYBcSZ-V4Vqbm_7dyTzFA0a3c25A@mail.gmail.com>",
	"to_parsed": [
		{
			"address": "s7st7.UXXlZS@inbox.testmail.app",
			"name": ""
		}
	],
	"oid": "10618-s7st7-5fvhznPexLDDkykmJBFoxe",
	"envelope_from": "hello@arslanaslam.me",
	"cc_parsed": [],
	"namespace": "s7st7",
	"from": "Muhammad Arslan Aslam <hello@arslanaslam.me>",
	"sender_ip": "please see headers",
	"html": "<div dir=\"ltr\">testemaikl<br clear=\"all\"><div><br></div>-- <br><div dir=\"ltr\" class=\"gmail_signature\" data-smartmail=\"gmail_signature\"><div dir=\"ltr\"><table style=\"font-family:Ubuntu\"><tbody><tr><td colspan=\"1\"><a href=\"mailto:hello@arslanaslam.me?subject=Hello\" target=\"_blank\"><img alt=\"\" src=\"https://i.imgur.com/73nOY7t.png\" style=\"max-width:200px;max-height:60px\"></a></td></tr><tr><td colspan=\"1\" rowspan=\"\"><strong>Software Engineer</strong> | <a href=\"https://brandjaws.com\" target=\"_blank\">BrandJaws</a></td></tr><tr><td colspan=\"\" rowspan=\"\"><a href=\"tel:+923134800125\" target=\"_blank\">+923134800125</a></td></tr><tr><td colspan=\"\" rowspan=\"\"><a href=\"https://arslanaslam.me\" target=\"_blank\">arslanaslam.me</a></td></tr><tr><td colspan=\"\" rowspan=\"\"><a href=\"https://twitter.com/arximughal\" target=\"_blank\">@arximughal</a></td></tr></tbody></table></div></div></div>\n",
	"to": "s7st7.UXXlZS@inbox.testmail.app",
	"tag": "UXXlZS",
	"spam_score": 1.8,
	"text": "testemaikl\n\n-- \n<hello@arslanaslam.me?subject=Hello>\n*Software Engineer* | BrandJaws <https://brandjaws.com>\n+923134800125\narslanaslam.me\n@arximughal <https://twitter.com/arximughal>\n",
	"from_parsed": [
		{
			"address": "hello@arslanaslam.me",
			"name": "Muhammad Arslan Aslam"
		}
	],
	"timestamp": 1623775571914,
	"id": "10618-s7st7-5fvhznPexLDDkykmJBFoxe",
	"downloadUrl": "https://object.testmail.app/api/10618-s7st7-5fvhznPexLDDkykmJBFoxe.eml"
};