import React, {useEffect, useState} from 'react';
import './styles.css';
import cx from 'classnames';
import axios from 'axios';
import Report from '../components/report/report';
import Navbar from '../components/navbar/navbar';
import Alert from '../components/alert/alert';
import Footer from '../components/footer/footer';

const generateTag = () => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < 6; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const Index = (props) => {
	const generatedTag = generateTag();
	const generatedWorkSpace = 's7st7.' + generatedTag + '@inbox.testmail.app';
	
	const [workspaceAddress, setWorkspaceAddress] = useState(generatedWorkSpace);
	const [tag, setTag] = useState(generatedTag);
	const [loading, setLoading] = useState(false);
	const [reportPresent, setReportPresent] = useState(false);
	const [retryCounter, setRetryCounter] = useState(0);
	const [report, setReport] = useState(null);
	const [showAlert, setShowAlert] = useState(false);
	const [alertText, setAlertText] = useState(null);
	const [alertType, setAlertType] = useState(null);
	
	useEffect(() => {
		if (retryCounter < 3 && retryCounter > 0) {
			getReport();
		}
	}, [retryCounter]);

	useEffect(() => {
		setTimeout(() => {
			setShowAlert(false);
		}, 5000);
	}, [showAlert]);
	
	const getReport = () => {
		setLoading(true);
		setReportPresent(false);
		let reqUrl = `https://api.testmail.app/api/json?apikey=${process.env.GATSBY_API_KEY}&namespace=s7st7&headers=true&spam_report=true&livequery=true&tag=${tag}`
		//let reqUrl = `https://api.testmail.app/api/json?apikey=${process.env.GATSBY_API_KEY}&namespace=s7st7&headers=true&spam_report=true&livequery=true&tag=u0VeH0`
		axios({
			method: 'get',
			url: reqUrl,
			// timeout: '3000'
		})
			.then(result => {
				console.log(result);
				if (result && result.data && result.data.emails) {
					if (result.data.emails.length < 1 && retryCounter < 3) {
						setRetryCounter(retryCounter + 1);
					} else {
						console.log('got the data --- now setting the report')
						setReport(result.data.emails[(result.data.emails.length - 1)]);
						setLoading(false);
						setReportPresent(true);
					}
				}
			}).catch(error => {
			console.log(error);
			if (retryCounter < 3) {
				setRetryCounter(retryCounter + 1);
			}
		});
	}
	
	const copyEmail = () => {
		navigator.clipboard.writeText(workspaceAddress).then(function () {
			console.log('Async: Copying to clipboard was successful!');
			showSuccessAlert();
		}, function (err) {
			console.error('Async: Could not copy text: ', err);
			showErrorAlert();
		});
	}

	const showSuccessAlert = () => {
		setAlertText('Email copied to clipboard successfully.');
		setAlertType('success');
		setShowAlert(true);
	};

	const showErrorAlert = () => {
		setAlertText('Email was not copied to clipboard. Please select the email manually.');
		setAlertType('danger');
		setShowAlert(true);
	};
	
	return (
		<>
			<Alert showAlert={showAlert} text={alertText} alertType={alertType} />
			<Navbar title="Email Validation - Free Spam Score Checker, Check Email Headers" />
			<div className='container mx-auto'>
				<div className='grid grid-cols-1'>
					{!reportPresent &&
						<p className='mt-5 mb-5 text-lg'>
							Send an email to the email address given below (click to copy):
						</p>
					}
					<button
						onClick={copyEmail}
						disabled={loading || reportPresent}
						className={cx('text-3xl border border-2 border-gray-600 border-dashed mt-2 text-center cursor-pointer font-mono p-2', (loading || reportPresent) && 'cursor-not-allowed')}
					>
						{workspaceAddress}
					</button>
					{!reportPresent &&
						<p className='mt-5 mb-5 text-lg'>
							Once done. Click the <span className='font-bold'>Generate Report</span> button below!
						</p>
					}
					{!reportPresent &&
					<div className='text-center'>
						<button
							disabled={loading || reportPresent}
							onClick={getReport}
							className={cx('transition duration-200 ease-in-out bg-transparent hover:bg-purple-600 font-semibold hover:text-white py-4 px-8 border text-lg border-purple-600 hover:border-transparent rounded disabled:bg-gray-300', (loading || reportPresent) && 'cursor-not-allowed bg-gray-300 hover:bg-gray-300')}>
							Generate Report
						</button>
					</div>
					}
				</div>
				{loading &&
					<div className='text-center mt-10 mb-10'>
						Fetching Report...
					</div>
				}
				{!loading && reportPresent &&
					<Report
						report={report}
					/>
				}
				{/* <Report
					report={report}
				/> */}
				{/* <Footer /> */}
			</div>
		</>
	);
};

export default Index;
